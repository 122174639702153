defineDs('DanskeSpil/Domain/Dantoto/Scripts/RacingArchive', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils',
], function (Utils) {
  var $years = $DS('a', '.year-selection');
  var $yearLists = $DS('.issues-list');

  var init = function () {
    reset();

    if ($years.length > 0) {
      Utils.logDeprecated('RacingArchive.js - $years found'); // Added by MIPE 2022-08-04
    }

    $years.click(function (e) {
      e.preventDefault();
      $years.removeClass('selected');
      $DS(this).addClass('selected');

      $yearLists.hide();
      $yearLists.filter('[data-year="' + $DS(this).data('year') + '"]').show();
    });
  };

  var reset = function () {
    $years.removeClass('selected');
    $yearLists.hide();

    $years.first().addClass('selected');
    $yearLists.first().show();
  };

  $DS(document).ready(function () {
    init();
  });
});



defineDs('DanskeSpil/Domain/Dantoto/Scripts/Ticker', ['Shared/Framework/Mithril/Scripts/Helpers/Utils'], function (Utils) {

  // Time constants, because magic numbers are bad.
  var MINUTE = 60 * 1000;
  var HOUR = MINUTE * 60;

  var previousData = null;
  var interval = null;
  var pollInterval = MINUTE;
  // Set initial time to be able to measure when we need to increase pollInterval
  var initialTime = (new Date()).getTime();
  // Define limits for when the pollInterval should increase
  var intervalIncreaseLimits = [
    HOUR / 2,
    HOUR * 2
  ];

  var stopTickerInterval = function () {
    clearInterval(interval);
    interval = null;
  };

  var startTickerInterval = function () {
    interval = setInterval(function () {
      checkFeed();
    }, pollInterval);
  };

  var restartTickerInterval = function () {
    stopTickerInterval();
    startTickerInterval();
  };

  var marquee = function () {
    $DS('.js-ticker').marquee({
      // speed in milliseconds of the marquee
      duration: 10000,
      // gap in pixels between the tickers
      gap: 0,
      // time in milliseconds before the marquee will start animating
      delayBeforeStart: 0,
      // 'left' or 'right'
      direction: 'left',
      // true or false - should the marquee be duplicated to show an effect of continues flow
      // duplicated: true //Not working correctly
    });
  };

  var getFeed = function () {
    return $DS.ajax({
      type: 'GET',
      url: DS.Config.CONTEXTPREFIX + '/scapi/ds/dantoto/ticker',
      contentType: 'application/json; charset=utf-8',
      dataType: 'json',
      error: function (ignoredXMLHttpRequest, textStatus, errorThrown) { console.log(errorThrown + ' ' + textStatus); },
    });
  };

  var isFeedDataSame = function (dataList, prevDataList) {
    if (dataList.length !== prevDataList.length) {
      return false;
    }
    // Check every element in current dataset against previous data set, .every returns true if all are same, othewise false
    return dataList.every(function (item, index) {
      var prevItem = prevDataList[index];
      return (item.Headline === prevItem.Headline && item.Text === prevItem.Text);
    });
  };

  var checkFeed = function () {
    var $containerDiv = $DS('.js-winners-spot');
    var $tickerDiv = $DS('.js-ticker');
    var webservice = getFeed();

    webservice.success(function (data) {

      // Early return if the data is the same
      if (previousData && isFeedDataSame(data, previousData)) {

        // Before early return, check if we need to increase polling value
        var elapsedTime = (new Date()).getTime() - initialTime;
        var closestLimit = intervalIncreaseLimits[0];

        // If we still have a limit left and we're above it
        if (closestLimit && elapsedTime >= closestLimit) {
          // Double poll interval to reduce server load
          pollInterval = pollInterval * 2;
          // Remove one ticker limit, as we're above it.
          intervalIncreaseLimits.shift();
          restartTickerInterval();
        }

        return;
      } else {
        // If we have changes, reset pollInterval and restart the interval
        pollInterval = MINUTE;
        restartTickerInterval();
      }

      $tickerDiv.empty();

      if (data.length > 0) {
        Utils.logDeprecated('Ticker.js - checkFeed response contains non-empty data');

        $containerDiv.show();

        var html = '';
        for (var i = 0; i < data.length; i++) {
          html = html + '<span class="item"><span class="headline">' + data[i].Headline + '</span><span class="message">' + data[i].Text + '</span></span>';
        }
        $DS($tickerDiv).html(html);
        marquee();
        previousData = data;

      } else {
        $containerDiv.hide();
      }
      previousData = data;
    });
  };

  var attachVisibilityListener = function () {
    // Stop or start interval based on whether user has minimized window or is in another tab to reduce server load.
    document.addEventListener('visibilitychange', function () {
      if (document.hidden && interval) {
        stopTickerInterval();
      } else {
        startTickerInterval();
      }
    });
  };

  var init = function () {

    attachVisibilityListener();

    startTickerInterval();
    checkFeed();
  };

  $DS(document).ready(function () {
    if ($DS('.js-ticker').length > 0) {
      init();

      // Utils.logDeprecated('Ticker.js - .js-ticker exists'); // Added by MIPE 2024-05-14 - Outcommented again 22/5-2024, .js-ticker found on /dantoto/spil-nu-side and /dantoto. Added logging on feed instead.
    }
  });

  return init;

});

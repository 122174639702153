defineDs('DanskeSpil/Domain/Dantoto/Scripts/NewslistView', [
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
],
function (Utils) {
  var init = function () {
    var $newslistWrapper = document.querySelector('#news-list-wrapper');
    var initialCount = $newslistWrapper.querySelectorAll('.size-single').length; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    var pagesize = parseInt($newslistWrapper.dataset.pagesize, 10);
    var newscount = parseInt($newslistWrapper.dataset.newscount, 10);
    var $readmorelink = document.querySelector('#read-more-link');

    if (initialCount < newscount) {
      $readmorelink.addEventListener('click', function (event) { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        var pageToFetch = $readmorelink.dataset.page;
        if (isNaN(pageToFetch)) {
          pageToFetch = 1;
        } else {
          pageToFetch++;
        }
        $readmorelink.setAttribute('data-page', pageToFetch); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
        var newPageId = 'page-' + pageToFetch;
        $newslistWrapper.appendChild('<span id=\'' + newPageId + '\'></span>'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector

        $DS('#' + newPageId).load('?page=' + pageToFetch + ' .news-list', function () {
          if ((pageToFetch + 1) * pagesize >= newscount) {
            $readmorelink.remove(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
          }
        });
        event.preventDefault();
      });
    } else {
      $readmorelink.remove(); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    }
  };

  document.addEventListener('DOMContentLoaded', function () {
    if (document.querySelector('#news-list-wrapper')) {
      Utils.logDeprecated('NewslistView.js loaded'); // Added by ANVP 2024-04-19
      init();
    }
  });
});
